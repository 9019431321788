<template>
  <div>
    <div class="crumbs">车辆管理 / 车辆报表</div>
    <div class="container">
      <div class="options_wrapper">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="车辆">
            <el-select v-model="currentCarID" placeholder="请选择">
              <el-option
                v-for="carItem in carList"
                :label="carItem.vihicle_number"
                :value="carItem.vihicle_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="月份">
            <el-date-picker
              v-model="monthValue"
              value-format="yyyy-MM"
              type="month"
              placeholder="选择月"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="chartDataChange"
              >查看</el-button
            >
            <el-button type="primary" size="medium" @click="editMonthReport"
              >编辑报表数据</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <div class="reports_wrapper">
        <div id="chartDom" style="width: 100%; height: 100%"></div>
      </div>
    </div>

    <el-dialog
      title="车辆管理 / 车辆报表 "
      :visible.sync="dialogVisible"
      :fullscreen="true"
      :before-close="handleClose"
    >
      <div class="dialog_top_wrapper">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="车辆">
            <el-select v-model="currentCarID" placeholder="请选择">
              <el-option
                v-for="carItem in carList"
                :label="carItem.vihicle_number"
                :value="carItem.vihicle_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="月份">
            <el-date-picker
              v-model="monthValue"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择月"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="medium"
              @click="monthReportDataChange"
              >切 换</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <div class="dialog_content">
        <div class="data_list">
          <div
            class="data_item"
            v-for="dataItem in carMonthReportData"
            :key="dataItem.id"
          >
            <div class="date">{{ dataItem.date }}</div>
            <div class="today_km">
              <span>今日里程：</span>
              {{ dataItem.today_km }} KM
            </div>
            <div class="collect_nums">
              <div class="lab">今日桶数：</div>
              <el-input-number
                v-model="dataItem.collect_nums"
                :min="0"
                :max="260"
                size="mini"
                label="桶数"
              ></el-input-number>
            </div>
            <div class="collect_nums">
              <div class="lab">收运吨数：</div>
              <el-input-number
                v-model="dataItem.collect_weight"
                :min="0"
                :max="260"
                size="mini"
                label="桶数"
              ></el-input-number>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEdit">取 消</el-button>
        <el-button type="primary" @click="saveEditCarMonthReportData"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getVihicleList, monthReport, vihicleBucket } from "../request/api";
export default {
  name: "cardReport",
  data() {
    return {
      dialogVisible: false,
      carList: [],
      currentCarID: 0,
      monthValue: "2024-01",
      carMonthReportData: [],
      oldcarMonthReportData: [],
      myChart: null,
      optionData: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: ["桶数", "吨数", "里程"],
        },
        xAxis: [
          {
            type: "category",
            data: ["1号", "2号", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            min: 0,
            max: 260,
            interval: 20,
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "桶数",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 桶";
              },
            },
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 35.6, 62.2, 32.6, 20.0, 6.4, 3.3,
            ],
          },
          {
            name: "吨数",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 吨";
              },
            },
            data: [
              12.0, 42.9, 37.0, 23.2, 25.6, 76.7, 35.6, 62.2, 32.6, 20.0, 99,
              3.3,
            ],
          },
          {
            name: "里程",
            type: "line",
            tooltip: {
              valueFormatter: function (value) {
                return value + " KM";
              },
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 75.6, 82.2, 48.7, 18.8, 6.0, 2.3,
            ],
          },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      console.log("关闭前------>", done);
    },

    getDaysOfMonth(yearMonth) {
      // 验证输入格式
      if (!/^\d{4}-\d{2}$/.test(yearMonth)) {
        throw new Error("Invalid input format. Expected YYYY-MM.");
      }

      const [year, month] = yearMonth.split("-").map(Number);

      const date = new Date(year, month - 1, 1);

      const daysInMonth = new Date(year, month, 0).getDate();

      const daysArray = [];

      for (let day = 1; day <= daysInMonth; day++) {
        date.setDate(day);

        daysArray.push(
          `${month.toString().padStart(2, "0")}-${day
            .toString()
            .padStart(2, "0")}`
        );
      }

      return daysArray;
    },

    async getCarListData() {
      const res = await getVihicleList({
        vihicle_type: "环卫车辆",
        is_all: 1,
      });

      this.carList = res.data.list;

      let _car_index = 0;

      if (this.$route.query.car_id) {
        _car_index = this.carList.findIndex(
          (item) => item.vihicle_id == this.$route.query.car_id
        );
      }

      this.currentCarID = this.carList[_car_index].vihicle_id;
      console.log(res);
    },

    async getCarMonthReport(month, vihicle_id) {
      const res = await monthReport({
        month,
        vihicle_id,
      });
      this.carMonthReportData = res.data;
      this.oldcarMonthReportData = JSON.parse(JSON.stringify(res.data));

      this.$loading().close();
    },
    async setChartsOptionData(month, vihicle_id) {
      const res = await monthReport({
        month,
        vihicle_id,
      });

      if (res.data.length > 0) {
        this.optionData.xAxis[0].data = this.getDaysOfMonth(month);
        const today_km_s = [];
        const collect_nums = [];
        const dun_nums = [];

        res.data.forEach((item) => {
          today_km_s.push(item.today_km);
          collect_nums.push(item.collect_nums);
          dun_nums.push(item.collect_weight);
        });

        this.optionData.series[0].data = collect_nums;
        this.optionData.series[1].data = dun_nums;
        this.optionData.series[2].data = today_km_s;

        this.$nextTick(() => {
          this.$loading().close();
          this.myChart && this.myChart.setOption(this.optionData);
        });
      }
    },

    async editMonthReport() {
      await this.getCarMonthReport(this.monthValue, this.currentCarID);
      this.dialogVisible = true;
    },
    initCharts() {
      const chartDom = document.getElementById("chartDom");
      this.myChart = echarts.init(chartDom);
    },
    // 图表查看切换
    chartDataChange() {
      console.log("this.monthValue", this.monthValue);
      if (!this.monthValue) {
        this.$message.error("请选择月份");
        return;
      }

      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.setChartsOptionData(this.monthValue, this.currentCarID);
    },

    // 图表报表数据切换
    monthReportDataChange() {
      if (!this.monthValue) {
        this.$message.error("请选择月份");
        return;
      }

      const flag = this.oldcarMonthReportData.some((it, i) => {
        if (
          it.collect_nums != this.carMonthReportData[i].collect_nums ||
          it.collect_weight != this.carMonthReportData[i].collect_weight
        ) {
          return true;
        } else return false;
      });

      if (flag) {
        this.$confirm("你修改了数据但未保存, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });

            this.getCarMonthReport(this.monthValue, this.currentCarID);
          })
          .catch(() => {});
      } else this.getCarMonthReport(this.monthValue, this.currentCarID);
    },
    // 批量保存月报
    async saveEditCarMonthReportData() {
      this.oldcarMonthReportData = JSON.parse(
        JSON.stringify(this.carMonthReportData)
      );

      const collect_bucket_obj = this.carMonthReportData.map((item) => {
        return {
          id: item.id,
          collect_nums: item.collect_nums,
          collect_weight: item.collect_weight,
        };
      });

      const res = await vihicleBucket({
        collect_bucket_obj: JSON.stringify(collect_bucket_obj),
      });

      this.$message.success(res.msg);

      this.setChartsOptionData(this.monthValue, this.currentCarID);
    },
    closeEdit() {
      const flag = this.oldcarMonthReportData.some((it, i) => {
        if (
          it.collect_nums != this.carMonthReportData[i].collect_nums ||
          it.collect_weight != this.carMonthReportData[i].collect_weight
        ) {
          return true;
        } else return false;
      });

      if (flag) {
        this.$confirm("你修改了数据但未保存, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.dialogVisible = false;
          })
          .catch(() => {});
      } else this.dialogVisible = false;
    },
  },

  async created() {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const cur_y_m = `${y}-${m < 10 ? "0" + m : m}`;
    this.monthValue = cur_y_m; // 首次默认当前月

    await this.getCarListData();
    this.setChartsOptionData(cur_y_m, this.$route.query.car_id);
  },
  async mounted() {
    this.initCharts();
  },
};
</script>

<style lang="less" scoped>
.crumbs {
  padding: 5px 20px;
  background-color: rgb(240, 240, 240);
}

.dialog_top_wrapper,
.options_wrapper {
  padding-top: 10px;
  padding-bottom: 13px;
  height: 36px;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  // height: 100vh;
  // background-color: pink;
}

.reports_wrapper {
  padding: 0 100px;
  height: 800px;
}

.dialog_content {
  width: 100%;
  // height: 600px;
  //background-color: pink;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.data_list {
  display: flex;
  flex-wrap: wrap;
  .data_item {
    // flex: 0 0 calc(100% / 10 - 2px); /* 十个格子一行，减去边框宽度 */
    width: 14.28%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    margin-bottom: -1px; /* 抵消下方格子的上边框 */
    margin-right: -1px; /* 抵消右边格子的左边框 */
    padding: 5px;
    text-align: center;

    .date {
      font-weight: bold;
    }

    .today_km {
      padding: 10px;
      text-align: left;
    }

    .collect_nums {
      padding: 10px;
      text-align: left;
      display: flex;
      align-items: center;
    }
  }
}
</style>
